<template>
  <div>
    <big-title>
      Contacto<span>.</span>
    </big-title>

    <forms-container
      :investor_id="InvestorId"
      :investor_status="InvestorStatus"
      :user_id="UserId"
      :moral-tabs="moralTabs"
      :pfae-tabs="pfaeTabs"
    />
  </div>
</template>

<script>
import FormsContainer from "@components/profile_shared/FormsContainer";
export default {
  name: "InvestorContact",
  components: {
    FormsContainer
  },
  data(){
    return {
      pfaeTabs: ['address'],
      moralTabs: ['address'],
    }
  }
}
</script>
